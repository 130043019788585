import upsExpressSaver from "./../assets/shipping-methods/UPS Express Saver.png";
import upsExpress from "./../assets/shipping-methods/UPS Express.png";
import upsStandard from "./../assets/shipping-methods/UPS Standard.png";
import dhlExpress from "./../assets/shipping-methods/DHL Express.png";
import dhlStandard from "./../assets/shipping-methods/DHL_Economy.png";
import tnt from "./../assets/shipping-methods/TNT.png";
import ems from "./../assets/shipping-methods/EMS Europe.png";
import pallet from "./../assets/shipping-methods/Pallet Shipping_2.png";
import personal from "./../assets/shipping-methods/Personal Collection.png";
import arrangements from "./../assets/shipping-methods/Customers arrangements_3.png";
import dpd from "./../assets/shipping-methods/dpd_next_day.png";

const dictionary = {
  "UPS Express Saver": upsExpressSaver,
  "UPS Express": upsExpress,
  "UPS Standard": upsStandard,
  "UPS COD": upsStandard,
  "UPS za pobraniem": upsStandard,
  "DHL Express": dhlExpress,
  "DHL Standard": dhlStandard,
  TNT: tnt,
  EMS: ems,
  "quoted individually": pallet,
  "Transport własny": pallet,
  arrangements,
  "Odbiór osobisty": personal,
  "Personal collection": personal,
  "DPD Next Day": dpd,
  default: arrangements,
};

export default function getShippingMethodPicture(displayname) {
  const candidate = Object.keys(dictionary).find((k) =>
    displayname.includes(k)
  );
  return dictionary[candidate || "default"];
}
