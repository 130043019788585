export default {
  COMMON: {
    CLOSE: "Zamknij",
  },
  ON_HOLD_POPUP: {
    HEADER: "Konto zablokowane",
    TEXT: "W związku z przeterminowanymi fakturami / przekroczeniem limitu, Twoje konto zostało zablokowane. W efekcie realizacja wszystkich otwartych zamówień zostanie wstrzymana do czasu uregulowania należności",
    BUTTON: "Zobacz nieopłacone faktury",
  },
  DEADSTOCK_POPUP: {
    BIG: "wielka",
    STOCK: "wyprzedaż",
    CLEARANCE: "magazynowa",
    UP_TO: "aż do",
    BUY_NOW: "sprawdź",
    OFF: "zniżki",
  },
  customPayments: {
    h1: "Wpłaty",
    header:
      "Proszę wpisać właściwą kwotę i kliknąć w przycisk dostawcy płatności",
    success: "Płatność zaakceptowana",
    amount: "Kwota",
  },
  modalLoaderText: "Proszę czekać",
  pricelistView: {
    chooseFileFormat: "Proszę wybrać format",
    pleaseStandBy: "Proszę czekać",
    header: "Pakiety danych o produktach",
    back: "POWRÓT",
    subHeader: "Wybierz producenta w celu pobrania pliku CSV lub Excel",
    priceList: "Zestawienie cen",
    itemsDataPack: "Pakiet danych o produktach",
  },
  bannerSlide: {
    productRange: "Zakres produktów",
    readMore: "Więcej",
    aeroflowOnBoard: "Aeroflow na pokładzie",
    checkOut: "Sprawdź",
    saberInStock: "Produkty Saber na stanie",
    cheaperSaberInStock: "Saber w niższych cenach",
    deadstock: "Czyszczenie magazynów Ozparts",
  },
  deadstock: "Czyszczenie magazynów",
  catalogues: {
    header: "Katalogi Online Ozparts",
    brands: "Wybierz katalog według producenta",
    brandsDeadstock: "Wybierz katalog wyprzedażowy według producenta",
    pickups: "Katalog pickupów i samochodów dostawczych",
    make: "Marka",
    model: "Model",
    year: "Rocznik",
    engine: "Silnik",
    variant: "Wariant",
    group: "Grupa",
    category: "Kategoria",
    addToCart: "Dodaj do koszyka",
    available: "Na stanie",
    inStock: "Na stanie",
    inTransit: "W drodze",
    deliveryIn: "Dostawa w ciągu 2-3 tygodni (od dnia zamówienia)",
    readyToOrder: "Na zamówienie",
    conversionKits: "Zestawy do konwersji",
    transmission: "Przekładnia",
    discSplineCount: "Ilość wypustów tarczy",
    discSplineDiameter: "Średnica wypustów tarczy",
    search: "Szukaj",
    itemAdded: "Dodano do koszyka",
    close: "Zamknij",
    motorsportPartSearch: "Cześci sportowe",
    caliperSize: "Wielkość zacisku",
    universalParts: "Części uniwersalne",
    class: "Klasa",
    findUniversalParts: "Katalog części uniwersalnych",
    selectGroup: "Grupa",
    checkAvailability: "Sprawdź na stronie produktu",
  },
  watchdogs: {
    header: "Ulubione i obserwowane",
    watched: "Obserwowane",
    favourite: "Ulubione",
    delete: "Usuń",
    add: "Do koszyka",
    inStock: "Na stanie",
  },
  order: {
    order: "Zamówienie",
    header: "Podsumowanie zamówienia",
    paid: "Zamówienie zostało opłacone",
    generalInformation: "Informacje ogólne",
    orderNumber: "Numer zamówienia",
    orderDate: "Data zamówienia",
    terms: "Warunki",
    type: "Typ",
    shippingAddress: "Adres dostawy",
    billingAddress: "Adres rozliczeniowy",
    shippingMethod: "Metoda dostawy",
    uploadLabel: "Wybierz plik z etykietą wysyłkową",
    addShippingLabel: "Dodaj etykietę wysyłkową",
    uploadShippingLabel: "Prześlij etykiety wysyłkowe",
    uploadedFile: "Przesłany plik",
    additionalInstructions: "Dodatkowe uwagi",
    noAdditionalInstructions: "Brak dodatkowych uwag",
    trackingNumbers: "Numery trackingowe",
    number: "Numer",
    weight: "Waga",
    waitingForAnUpdate: "Oczekiwanie na aktualizację",
    payByPayPal: "Zapłać za pośrednictwem PayPal",
    payByRevolut: "Zapłać kartą kredytową za pośrednictwą Revolut",
    payByPayU: "Zapłać za pośrednictwem PayU",
    payByBankRevolut: "Przelew bankowy (Revolut)",
    for: "Na rzecz",
    accountNumber: "Numer konta",
    sortCode: "Kod rozliczeniowy",
    payByBankTransfer: "Przelew bankowy",
    basket: "Koszyk",
  },
  summary: {
    referenceNumber: "Numer referencyjny",
    header: "Podsumowanie",
    shippingAddress: "Adres dostawy",
    backorder: "Oczekujące zamówienia",
    sendTogether:
      "Wszystkie produkty wysłać razem w terminie późniejszym, gdy całość zamówienia będzie dostępna.",
    sendNow:
      "Produkty dostępne wysłać natychmiast. Produkty niedostępne wysłać osobno w terminie późniejszym.",
    shippingMethod: "Metoda dostawy",
    labelLater: "Etykietę będzie można dosłać w kolejnym kroku",
    paymentMethod: "Metoda płatności",
    additionalInstructions: "Dodatkowe uwagi",
    noAdditionalInstructions: "Brak dodatkowych uwag",
    placeOrder: "Złóż zamówienie",
    back: "Powrót do metod płatności",
  },
  paymentMethods: {
    header: "Metody płatności",
    continueToSummary: "Przejdź do podsumowania",
    back: "Powrót do metod dostawy",
    referenceNumberHeader: "Numer referencyjny (opcjonalnie)",
    referenceNumber: "Numer referencyjny",
  },
  shippingMethodsObj: {
    custom: {
      selectCarrier: "Dostawca",
      selectMethod: "Metoda",
      details: "Szczegóły",
      label: "Numer konta u wybranego dostawcy",
    },
    shippingMethods: "Metody dostawy",
    additionalInstructions: "Dodatkowe instrukcje",
    instruction:
      "Jeśli masz dodatkowe życzenia dotyczące realizacji Twojego zamówienia (np. chcesz zachować to zamówienie do wysyłki w terminie późniejszym) lub instrukcje dotyczące pakowania (np. chcesz, abyś paczka została wysłana z nieoznakowaną taśmą) lub inne, poinformuj nas o tym.",
    continueToPaymentMethods: "Przejdź do metod płatności",
    backToBackorder: "Powrót do oczekujących zamówień",
    backToShippingAddress: "Powrót do adresu dostawy",
    other: "Inny",
    economy: "Ekonomiczna",
    express: "Ekspresowa",
    customerAccountNumber: "Numer konta klienta",
    shippingLabel: "Etykieta",
  },
  backorder: {
    header: "Oczekujące zamówienia",
    instruction:
      "Zamówione przez Ciebie następujące produkty nie są w chwili obecnej dostępne. W związku z tym prosimy o informację jak mamy zrealizować zamówienie wybierając jedną z następujących opcji.",
    sendSeparately:
      "Produkty dostępne wysłać natychmiast. Produkty niedostępne wysłać osobno w terminie późniejszym. (Mogą wystąpić dodatkowe opłaty za dodatkową wysyłkę).",
    sendTogether:
      "Wszystkie produkty wysłać razem w terminie późniejszym, gdy całość zamówienia będzie dostępna.",
    continueToShippingMethods: "Przejdź do metod dostawy",
    backToShippingAddress: "Powrót do adresu dostawy",
  },
  stepper: {
    first: "Metody dostawy i płatności",
    second: "Podsumowanie",
    third: "Płatność i potwierdzenie",
  },
  basketObj: {
    empty: "Twój koszyk jest pusty",
    yourItems: "Twoje produkty",
    proceedToCheckout: "Przejdź do kasy",
    continueShopping: "Kontynuuj zakupy",
    discountCodeChanged: "Zmieniono kod zniżkowy",
    close: "Zamknij",
    qty: "Ilość",
    remove: "Usuń",
    orderSummary: "Podsumowanie zamówienia",
    subtotal: "Suma częściowa",
    items: "sztuk",
    shippingCost: "Koszt dostawy",
    paymentMethodFee: "Opłata za metodę płatności",
    airfreightServices: "Usługi lotnicze",
    tax: "Podatek",
    total: "Suma",
    availableWeight: "Waga dostępnych przedmiotów",
    backorderWeight: "Waga oczekujących przedmiotów",
    totalWeight: "Waga w sumie",
    kgs: "kg",
  },
  sideBasket: {
    header: "Szczegóły zamówienia",
    headerReturn: "Szczegóły zwrotu",
  },
  shippingAddress: {
    shippingAddress: "Adres dostawy",
    addNewAddress: "Dodaj nowy adres",
    editAddress: "Edytuj wybrany adres",
    continue: "Dalej",
    placeWithDefault: "Złóż zamówienie z domyślnymi ustawieniami",
    selectAddress: "Wybierz adres dostawy",
    label: "Etykieta",
    dispatchDetails:
      "Na ten adres zostanie wysłane powiadomienie o nadaniu przesyłki",
    name: "Adresat",
    address: "Adres",
    country: "Państwo",
    phone: "Numer telefonu",
  },
  checkout: {
    showEmailField: "Powiadomienie o nadaniu przesyłki dla odbiorcy",
    header: "Kasa",
  },
  units: {
    week: "tydz.",
    day: "dni",
  },
  returns: {
    success: "Zwrot zarejestrowany",
    successText: "Zwrot {name} zarejestrowany",
    details: "Podaj szczegóły",
    quantity: "Ilość",
    totalAmount: "W sumie",
    return: "Oznacz do zwrotu",
    specialOrderItem: "Przedmiot na zamówienie - produkt nie podlega zwrotowi",
    header: "Podaj numer części, którą chcesz zwrócić",
    subheader:
      "Proszę wygenerować formularz zwrotu, wydrukować go i dołączyć do przesyłki wraz z przedmiotami, które chcesz zwrócić.",
    button: "Wygeneruj i wydrukuj",
    placeholder: "Np. DBA 650S",
    back: "POWRÓT",
    backToReturns: "POWRÓT",
    date: "Data",
    number: "Numer",
    type: "Typ",
    selectProductsToReturn: "Zaznacz przedmioty do zwrotu",
    reason: "Powód",
    from: "OD",
    to: "DO",
    filter: "FILTRUJ",
    reasons: {
      doesNotFit: "Produkt nie pasuje do samochodu",
      doesNotNeed: "Anulowanie zamówienia",
      mistake: "Produkt nieprawidłowo/ przypadkowo zamówiony",
      damaged: "Otrzymany produkt jest niekompletny/ uszkodzony",
      wrongDescription: "Produkt niezgodny z opisem",
      other: "Inny",
    },
    table: {
      item: "Produkt",
      quantity: "Ilość",
      invoice: "Nr faktury",
      date: "Data",
      amount: "Kwota",
    },
    sort: {
      date: "Sortuj wg daty",
      number: "Sortuj wg numeru faktury",
      amount: "Sortuj wg kwoty",
    },
  },
  modal: {
    returnLength: "Proszę wybrać co najmniej jedną część",
    successTitle: "Sukces",
    contactFormSubmitted: "Przesłano formularz kontaktowy",
  },
  validations: {
    required: "To pole jest wymagane",
    format: "Niewłaściwy format",
  },
  quickForm: {
    make: "Marka",
    model: "Model",
    year: "Rocznik",
    engine: "Pojemność silnika",
    drivingStyle: "Styl jazdy",
    competitionType: "Rodzaj zawodów",
    quickContactForm: "Szybki formularz kontaktowy",
    send: "Wyślij",
    quickContactFormHeader:
      "Czy ta część będzie pasować do Twoje auta? Sprawdźmy to!",
    drivingStyles: {
      daily: "Codzienne użytkowanie",
      occasional: "Codzienne użytkowanie i okazjonalne na torze",
      track: "Tylko na torze",
    },
  },
  superseded: {
    mainButtonText: "Ta część została zastępiona przez",
    mainButtonTextAlt: "Ta część zostanie zastępiona przez",
    checkDetails: "Szczegóły",
    modalHeader: "Część zastąpiona",
  },
  product: {
    CONVERSION: {
      HEADER: "Sprzęgło i koło zamachowe",
      CONTENT:
        "W celu potwierdzenia czy dana konwersja jest odpowiednia, prosimy o wypełnienie {link} i przesłania go razem z wymaganymi zdjęciami na adres email:",
      FOOTER: "Po otrzymaniu wymaganych informacji skontaktujemy się wkrótce",
      LINK_TEXT: "formularza PDF",
    },
    WRONG_QTY: "Prawidłowa wielokrotność ilości - {moq}",
    ENGRAVEMENT: "z homologacją ECE R90",
    leadTime: "czas dostawy",
    dbaType: {
      kit: "2 tarcze + zestaw klocków",
      disc: "1 tarcza",
      pad: "1 komplet klocków",
    },
    enquiry: {
      header: "Wyślij zapytanie",
      message: "Wiadomość",
      send: "Wyślij",
    },
    services: {
      choose: "Dodatkowe usługi",
      header:
        "Proszę wybrać usługę dla części, których nie ma obecnie w magazynie",
      addWithoutServices: "Dodaj do koszyka bez dodatkowych usług",
      addWithServices: "Dodaj do koszyka z wybranymi usługami",
    },
    expectedOn: "spodziewana dostawa",
    inTransit: "W trakcie dostawy",
    inEUStock: "W magazynie PL",
    checkOutShippingTimes: "Sprawdź czas dostawy",
    inStock: "W magazynie",
    noData: "Brak informacji po stronie producenta",
    weeksDelivery: "Czas dostawy: {weeks} {unit}",
    availability: "Dostępne",
    manStock: "Stan magazynowy producenta",
    inManStock: "Stan magazynowy producenta",
    inManStockAnonymous: "Gotowy do zamówienia",
    inMarketingStock: "Magazyn marketingowy",
    inDelivery: "Dostępne w dostawie",
    subjectToManStock: "W zależności od dostępności w magazynie producenta",
    pcs: "sztuk",
    rrpFull: "Rekomendowana cena detaliczna, bez VAT",
    rrp: "Rekomendowana cena detaliczna",
    oldPrice: "Standardowa cena",
    yourPrice: "Twoja cena",
    noVat: "bez VAT",
    forOnePiece: "Cena za sztukę",
    moq: "Twoja cena przy zakupie minimum",
    notReturnable: "Towar nie podlega zwrotowi",
    quantity: "Ilość",
    sendAnEnquiry: "Wyślij zapytanie",
    icons: {
      itemNonReturnableTooltip:
        "Towar nie podlega zwrotowi / anulowaniu zamówienia",
      inFavourites: "W ulubionych",
      addToFavourites: "Dodaj do ulubionych",
      inWatchdog: "Obserwuję dostępność",
      addToWatchdog: "Powiadom o zmianie dostępności",
      itemNonReturnable: "Towar nie podlega zwrotowi",
      itemReturnable: "30 dni na zwrot",
    },
  },
  pricelist: "Cennik",
  components: "Części w zestawie",
  showConversionEnquiry: "Wyślij zapytanie",
  myAccount: "Moje konto",
  login: "Zaloguj",
  logout: "Wyloguj",
  basket: "Koszyk",
  search: "Szukaj wg numeru części",
  ourBrands: "Nasze marki",
  news: "Aktualności",
  catalogue: "Katalog",
  website: "Strona internetowa",
  newProductsInStock: "Nowe produkty",
  usefulFiles: "Przydatne pliki",
  importantLinks: "Przydatne linki",
  usefulFiles1: "Poradniki / Katalogi",
  usefulFiles2: "Grafiki (Logo / Postery / Banery / Broszury )",
  usefulFiles3: "Zdjęcia produktów",
  usefulFiles4: "Materiały wideo",
  usefulFiles5: "Wsparcie techniczne / Instrukcje montażowe",
  youtube: "Kanał Youtube",
  applications: "Aplikacje",
  related: "Powiązanie przedmioty",
  overview: "Informacje ogólne",
  registeredOffice: "Siedziba Office",
  billingPurposes: "kwestie rozliczeniowe",
  officeAndWarehouse: "Biuro i magazyn",
  contactUs: "Kontakt",
  poland: "Polska",
  openingHours: "Godziny otwarcia",
  openingHours1:
    "Biuro jest czynne w godzinach 8:30 – 16:30 (CEST/CET), od poniedziałku do piątku.",
  openingHours2:
    "Po wysłaniu formularza skontaktujemy się z Tobą w ciągu 24 godzin.",
  banner: "TWÓJ DYSTRYBUTOR CZEŚCI DO SAMOCHODÓW SPORTOWYCH I 4X4",
  banner1:
    "Jesteśmy europejskim magazynem dystrybuującym wysokiej jakości części producentów australijskich i amerykańskich - DBA (Disc Brakes Australia), Xtreme Clutch, Xtreme Outback, Clutch Pro, Xforce, Pedders, Hawk, Turbosmart, Whiteline, ACL Race Series, Redarc, Saber i Aeroflow.",
  readMore: "więcej",
  marqueeText:
    "Due to the national holiday, we will be closed on the 11th of November 2020. Please, take it into consideration when placing the orders.",
  addToCart: "Dodaj do koszyka",
  noLongerAvl: "Przedmiot niedostępny",
  additionalServices: "Opcje dodatkowe",
  pcs: "sztuk",
  signIn: "Logowanie",
  returningCustomer: "Mam już konto",
  newCustomer: "Nowy klient",
  password: "Hasło",
  returningCustomerSub: "Zaloguj się na istniejące konto",
  newCustomerSub:
    "If you are interested in possibility of becoming a customer/dealer/distributor please fill out this form",
  signInButton: "Zaloguj się",
  forgotPassword: "Zapomniałeś hasła",
  updatePassword: "Zmień hasło",
  updatePassword1: "Wyślij dane do zmiany hasła",
  saveChanges: "Zapisz zmiany",
  anonymous: "Użytkownik nieznany",
  profile: "Mój profil",
  defaultShipping: "Domyślny adres dostawy",
  edit: "Zmień",
  addressBook: "Książka adresowa",
  settings: "Ustawienia",
  otherTools: "Pozostałe narzędzia",
  editAddress: "Zmień adres",
  cancel: "Anuluj",
  addNewAddress: "Dodaj adres",
  newAddress: "Nowy adres",
  seeAllTransactions: "Zobacz wszystkie transakcje",
  exportPriceList: "Pobierz cenniki i dane",
  orderStatusReport: "Status zamówień",
  noDefaultAddress:
    "Brak domyślnego adresu dostawy. Użyj książki adresowej, aby dodać",
  close: "Zamknij",
  mySettings: "Ustawienia",
  delete: "Usuń",
  makeDefaultShipping: "Ustaw jako adres domyślny",
  defaultShippingAddress: "Domyślny adres wysyłki",
  defaultBillingAddress: "Domyślny adrees rozliczeniowy",
  allOrderHistory: "PEŁNA HISTORIA ZAMÓWIEŃ >",
  myAccount: "Moje konto",
  recentOrders: "Ostatnie zamówienia",
  vehicleInformationSheets: "Broszury informacyjne dla poszczególnych modeli",
  manOnlineCataloguesLookingFor: "NIE ZNALAZŁEŚ TEGO, CZEGO SZUKASZ?",
  manOnlineCatalogues: "ONLINE KATALOGI PRODUCENTÓW",
  clearanceOnlineCatalogues: "JESTEŚ NA STRONIE KATALOGÓW WYPRZEDAŻOWYCH",
  manOnlineCataloguesDsc:
    "Jeśli na naszej stronie nie znaleźliście interesującego Was produktu zachęcamy do sprawdzenia katalogów naszych producentów. Jeśli znajdziesz produkt bezpeśrednio u producenta, poinformuj nas wysyłając wiadomość na info@ozparts.eu, a my zamówimy go dla Ciebie!",
  clearanceOnlineCataloguesDsc:
    "Jesteś na stronie wyprzedaży magazynowej Ozparts. Aby powrócić do pełnego katalogu kliknij {here}.",
  here: "tutaj",
  clearanceBrandCircle: "Katalog wyprzedażowy",
  techDetails: "Wymiary",
  panel: {
    statementTemplate: "Potwierdzenie salda należności",
    warranties: "Formularze gwarancyjne",
    makeReturn: "Zwroty",
    terms: {
      header: "Warunki i zobowiązania",
      accepted: "zaakceptowano",
      declined: "brak akceptacji",
      decline: "Cofnij zgodę",
      status: "Aktualny status",
      accept: "Zaakceptuj",
    },
    cooperation: {
      header: "Warunki współpracy",
      payment: "Zasady płatności",
      limit: "Limit kredytowy",
    },
    returns: {
      pdf: "Korekta",
      header: "Zgłoszone zwroty",
      number: "Numer",
      date: "Data",
      reason: "Powód",
      status: "Status",
      quantity: "Ilość",
      details: "Szczegóły",
    },
  },
  returnPreview: {
    preview: "Zwrot",
    header: "Podsumowanie zwrotu",
    generalInformation: "Informacje ogólne",
    returnNumber: "Numer zwrotu",
    returnDate: "Data zwrotu",
    shippingAddress: "Adres dostawy",
    billingAddress: "Adres rozliczeniowy",
    reason: "Powód",
    DOWNLOAD_PDF: "Pobierz PDF",
    DOWNLOAD_CREDIT_MEMO: "Pobierz notę korygującą",
  },
  history: {
    PAY_OVERDUE: "Zapłać zobowiązanie w wysokości",
    from: "Od",
    to: "Do",
    clear: "Wyczyść",
    export: "Eksportuj",
    back: "POWRÓT",
    typeToSearch: "Wyszukaj po numerze transakcji",
    types: {
      allTypes: "Wszystkie transakcje",
      salesOrder: "Zamówienia",
      invoice: "Faktury",
      payment: "Płatności",
    },
    sortBy: {
      date: "Sortuj według daty",
      number: "Sortuj według numeru",
      amount: "Sortuj według kwoty",
      status: "Sortuj według statusu",
      type: "Sortuj według typu",
    },
  },
  historyTable: {
    number: "Numer",
    type: "Typ",
    date: "Data",
    amount: "Kwota",
    status: "Status",
  },
};
