<template>
  <div class="d-flex align-center flex-column wrapper">
    <div
      class="shipping-method d-flex justify-center align-center"
      :class="{ selected }"
      @click="selectShippingMethod"
    >
      <img
        :src="getShippingMethodPicture(shippingMethod.displayname)"
        :alt="shippingMethod.displayname"
      />
    </div>
    <div class="text-center description">{{ shippingMethod.displayname }}</div>
  </div>
</template>

<script>
import getShippingMethodPicture from "@/vars/shippingMethods";

export default {
  name: "ShippingMethod",
  props: {
    shippingMethod: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const selectShippingMethod = () =>
      ctx.emit("selectShippingMethod", props.shippingMethod._id);

    return {
      selectShippingMethod,
      getShippingMethodPicture,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100px;
}

.shipping-method {
  border: 1px solid #707070;
  background-color: #f4f4f4;
  filter: grayscale(100%);
  border-radius: 50%;
  width: 75px;
  height: 75px;

  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }

  &:hover {
    cursor: pointer;
    background-color: white;
    filter: grayscale(0);
  }
}

.description {
  padding-top: 8px;
  font-size: 12px;
}

.selected {
  border: 1px solid black;
  filter: grayscale(0);
}
</style>
