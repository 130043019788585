import bankTransfer from "./../assets/payment-methods/40x40_bank transfer.png";
import creditCard from "./../assets/payment-methods/Ozparts_PM_CreditCard.png";
import payPal from "./../assets/payment-methods/Ozparts_PM_PayPal.png";
import payU from "./../assets/payment-methods/Ozparts_PM_PayU.png";
import upsStandard from "./../assets/shipping-methods/UPS Standard.png";

const dictionary = {
  Transfer: bankTransfer,
  PayU: payU,
  PayPal: payPal,
  "Credit Card": creditCard,
  UPS: upsStandard,
};

export default function getPaymentMethodPicture(displayname) {
  const candidate = Object.keys(dictionary).find((k) =>
    displayname.includes(k)
  );
  return dictionary[candidate];
}
