<template>
  <div
    class="header-wrapper"
    :class="{ stickyClass: sticky }"
    :style="{ 'background-color': back, color: font }"
  >
    <div class="d-flex top">
      <div class="flex-1">
        <slot name="left"></slot>
      </div>
      <h2>
        {{ text }}
      </h2>
      <div class="flex-1">
        <slot name="right"></slot>
      </div>
    </div>
    <hr class="line" :style="{ 'border-top': '2px solid ' + font }" />
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    sticky: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    back: {
      type: String,
      default: "",
    },
    font: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.stickyClass {
  position: sticky;
  top: 0;
  z-index: 1;
}

.header-wrapper {
  color: black;
  padding: 1em;

  .top {
    padding-top: 10px;
  }

  h2 {
    text-transform: uppercase;
    word-spacing: 5px;
    font-size: 16px;
    line-height: 1.5;
  }

  .flex-1 {
    flex: 1;
  }

  .line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid black;
    margin: 1em auto;
    padding: 0;
    margin-top: 1em;
    width: 7em;
  }
}
</style>
