<template>
  <v-dialog v-model="modal" width="500" @click:outside="closeModal">
    <v-card class="popup" @click="closeModal">
      <div class="close">X</div>
      <div class="text big">{{ $t("DEADSTOCK_POPUP.BIG") }}</div>
      <div class="text">{{ $t("DEADSTOCK_POPUP.STOCK") }}</div>
      <div class="text clearance">{{ $t("DEADSTOCK_POPUP.CLEARANCE") }} !</div>
      <div class="text">{{ $t("DEADSTOCK_POPUP.UP_TO") }}</div>
      <div class="text arrow d-none d-md-block" @click="goToStockClearance">
        <div class="d-flex justify-space-between">
          <div>60%</div>
          <div>{{ $t("DEADSTOCK_POPUP.BUY_NOW") }}</div>
        </div>
        <svg height="120" width="50" class="tip">
          <polygon points="0,0 50,60 0,120" />
        </svg>
      </div>
      <div class="d-sm-none">
        <div class="arrow text mobile mb-2">60%</div>
        <div class="arrow text mobile" @click="goToStockClearance">
          {{ $t("DEADSTOCK_POPUP.BUY_NOW") }}
        </div>
      </div>
      <div class="text off">{{ $t("DEADSTOCK_POPUP.OFF") }}</div>
      <svg width="200" height="200" class="circle">
        <circle cx="120" cy="120" r="100" fill="white" />
      </svg>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeadstockPopup",
  setup(_, ctx) {
    const modal = false;

    const closeModal = () => {
      modal.value = false;
      ctx.root.$store.commit("updateDeadstockPopup", false);
    };

    const goToStockClearance = () => {
      ctx.root.$router.push("/stock-clearance");
      closeModal();
    };

    return {
      modal,
      closeModal,
      goToStockClearance,
    };
  },
};
</script>

<style lang="scss" scoped>
.popup {
  background-color: rgb(245, 245, 245);
  position: relative;
  padding: 80px 0 40px 0;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .text {
    padding: 0 40px;
    text-transform: uppercase;
    font-size: 38px;
    font-weight: 900;
  }

  .big {
    color: var(--red);
  }

  .clearance {
    color: white;
    background-color: black;
  }

  .arrow {
    color: white;
    background-color: var(--red);
    width: 80%;
    position: relative;
    z-index: 1;
    padding-right: 10px;

    &:hover {
      cursor: pointer;
    }

    .tip {
      position: absolute;
      right: 0;
      fill: var(--red);
      transform: translate(50px, -85px);
    }
  }

  .mobile {
    width: 100%;
  }

  .off {
    color: var(--red);
  }

  .circle {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
