<template>
  <v-dialog
    :value="modal.show || modal.value || value"
    max-width="340"
    @click:outside="handleClick"
    @keydown="handleClick"
  >
    <v-card>
      <v-card-title class="headline">{{
        modal.header || "Error"
      }}</v-card-title>
      <v-card-text v-if="modal.html" v-html="modal.html" />
      <v-card-text v-else>{{
        modal.text || modal.message || value
      }}</v-card-text>
      <v-card-actions>
        <div></div>
        <v-spacer />
        <v-btn text @click="handleClick">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UniversalModal",
  props: {
    modal: {
      type: Object,
      default: () => ({
        show: false,
        text: "",
        message: "",
        html: false,
      }),
    },
    value: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClick() {
      this.$emit("closeModal");
    },
  },
};
</script>
