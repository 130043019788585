<template>
  <section class="payments">
    <summary-item
      v-if="
        selectedWidget === 'paypal' ||
        selectedWidget === 'paypaluk' ||
        selectedWidget === '607468324e5b797a767fe87d'
      "
    >
      <h2 class="mb-4">{{ $t("order.payByPayPal") }}</h2>
      <pay-pal :order="order" @success="handleSuccess" @error="handleError" />
    </summary-item>
    <summary-item
      v-if="selectedWidget === 'revolutuk' || selectedWidget === 'revolut'"
    >
      <h2 class="mb-4">{{ $t("order.payByRevolut") }}</h2>
      <revolut :order="order" @success="handleSuccess" @error="handleError" />
    </summary-item>
    <summary-item v-if="selectedWidget === 'payu'">
      <h2>{{ $t("order.payByPayU") }}</h2>
      <pay-u :order="order" @success="handleSuccess" />
    </summary-item>
    <universal-modal :modal="error" @closeModal="resetError" />
  </section>
</template>

<script>
import { ref } from "@vue/composition-api";
import { confirmPayment } from "@/services/api";
import SummaryItem from "@/components/checkout/SummaryItem.vue";
import PayPal from "@/components/order/PayPal.vue";
import Revolut from "@/components/order/Revolut.vue";
import PayU from "@/components/order/PayU.vue";
import UniversalModal from "@/components/common/UniversalModal.vue";

const emptyError = {
  value: false,
  message: "",
};

export default {
  name: "PaymentWidgets",
  components: {
    PayPal,
    Revolut,
    PayU,
    SummaryItem,
    UniversalModal,
  },
  props: {
    selectedWidget: {
      type: String,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const error = ref(emptyError);

    const resetError = () => {
      error.value = emptyError;
    };

    const handleError = (message) => {
      error.value = {
        value: true,
        message,
      };
    };

    const handleSuccess = async (details) => {
      if (details.status === "COMPLETED") {
        await confirmPayment(
          ctx.root.$route.params.id || ctx.root.$route.params.basket,
          props.selectedWidget
        );
        ctx.root.$router.push({
          name: "order",
          params: {
            status: "success",
            id: ctx.root.$route.params.id || ctx.root.$route.params.basket,
          },
        });
      }
    };

    return {
      error,
      handleError,
      handleSuccess,
      resetError,
    };
  },
};
</script>

<style lang="scss" scoped></style>
