<template>
  <div>
    <v-overlay :value="showDialog" z-index="200"></v-overlay>
    <v-footer v-if="showDialog" fixed class="content">
      <v-container>
        <div class="d-flex flex-column flex-md-row justify-center align-center">
          <p>
            This site uses cookies and similar technologies to help us to make
            this site work properly and to provide you with a better browsing
            experience. Find out more about how we use cookies and how you can
            change your preferences by reading our
            <router-link to="/cookies-policy">Cookies Policy</router-link>.
          </p>
          <div class="button agree" @click="acceptCookies()">I agree</div>
          <div class="button decline" @click="declineCookies()">Decline</div>
        </div>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import { bootstrap } from "vue-gtag";
import { mapState, mapMutations } from "vuex";

export default {
  name: "Cookie",
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapState(["cookieConsent"]),
  },
  created() {
    if (!this.cookieConsent) {
      this.showDialog = true;
    } else {
      this.loadCookies();
    }
  },
  methods: {
    ...mapMutations(["setCookieConsent", "logOut"]),
    loadLA() {
      if (document.location.host.includes("ozparts.eu")) {
        (function (d, src, c) {
          var t = d.scripts[d.scripts.length - 1],
            s = d.createElement("script");
          s.id = "la_x2s6df8d";
          s.async = true;
          s.src = src;
          s.onload = s.onreadystatechange = function () {
            var rs = this.readyState;
            if (rs && rs != "complete" && rs != "loaded") {
              return;
            }
            c(this);
          };
          t.parentElement.insertBefore(s, t.nextSibling);
        })(
          document,
          "https://ozparts.ladesk.com/scripts/track.js",
          function (e) {
            LiveAgent.createButton("41fzs7bz", e);
          }
        );
      }

      if (document.location.host === "ozparts.uk") {
        (function (d, src, c) {
          var t = d.scripts[d.scripts.length - 1],
            s = d.createElement("script");
          s.id = "la_x2s6df8d";
          s.async = true;
          s.src = src;
          s.onload = s.onreadystatechange = function () {
            var rs = this.readyState;
            if (rs && rs != "complete" && rs != "loaded") {
              return;
            }
            c(this);
          };
          t.parentElement.insertBefore(s, t.nextSibling);
        })(
          document,
          "https://ozparts.ladesk.com/scripts/track.js",
          function (e) {
            LiveAgent.createButton("y46ef3g8", e);
          }
        );
      }
    },
    loadGTag() {
      bootstrap().then((gtag) => {
        // all done!
      });
    },
    loadCookies() {
      this.loadLA();
      this.loadGTag();
    },
    acceptCookies() {
      this.setCookieConsent(true);
      this.loadCookies();
      this.showDialog = false;
    },
    declineCookies() {
      this.setCookieConsent(false);
      this.logOut();
      this.showDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 210;
  font-weight: bolder;
  font-family: "Nunito Sans", sans-serif;
}

.button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: normal;
  margin: 10px 10px 5px 10px;
  padding: 10px;

  @media screen and (min-width: 960px) {
    margin: 0 10px;
    width: 200px;
  }

  &:hover {
    cursor: pointer;
  }
}

.agree {
  background-color: var(--red);
}

.decline {
  background-color: gray;
}
</style>
