<template>
  <div>
    <h2 class="header mb-6">{{ $t("summary.header") }}</h2>
    <summary-item editable target="shipping-address">
      <h2>{{ $t("summary.shippingAddress") }}</h2>
      <div class="font-weight-bold">{{ shippingAddress.addressee }}</div>
      <div>{{ shippingAddress.address }}</div>
      <div>{{ shippingAddress.zip }} {{ shippingAddress.city }}</div>
      <div>{{ shippingAddress.countryname || shippingAddress.country }}</div>
      <div class="my-2">{{ shippingAddress.phone }}</div>
    </summary-item>
    <summary-item v-if="backorderItems.length" editable target="backorder">
      <h2>{{ $t("summary.backorder") }}</h2>
      <p>
        {{
          sendAsComplete ? $t("summary.sendTogether") : $t("summary.sendNow")
        }}
      </p>
    </summary-item>
    <summary-item editable target="shipping-method">
      <h2>{{ $t("summary.shippingMethod") }}</h2>
      <div>{{ shippingMethod.displayname }}</div>
      <div v-if="shippingMethod._id === 'own'">
        <div>{{ $store.state.customShipping.carrier }}</div>
        <div>{{ $store.state.customShipping.speed }}</div>
        <div
          v-if="$store.state.customShipping.label === 'Customer Account Number'"
        >
          {{ $store.state.customShipping.label }}:
          {{ $store.state.customShipping.labelNumber }}
        </div>
        <div v-else>
          <div>
            {{ $store.state.customShipping.label }}
          </div>
          <div class="text--disabled">
            {{ $t("summary.labelLater") }}
          </div>
        </div>
      </div>
    </summary-item>
    <summary-item editable target="payment-method">
      <h2>{{ $t("summary.paymentMethod") }}</h2>
      <div>{{ paymentMethod.name }}</div>
      <div class="text--disabled">{{ paymentMethod.description }}</div>
    </summary-item>
    <summary-item v-if="cart.referencenumber" editable target="payment-method">
      <h2>{{ $t("summary.referenceNumber") }}</h2>
      <div>{{ cart.referencenumber }}</div>
    </summary-item>
    <summary-item editable target="shipping-method">
      <h2>{{ $t("summary.additionalInstructions") }}</h2>
      <div>
        {{ $store.state.memo || $t("summary.noAdditionalInstructions") }}
      </div>
    </summary-item>
    <checkout-button
      color="mt-4"
      :loading="isLoading"
      :disabled="isLoading"
      @click="placeOrder"
      >{{
        $store.state.user.paymentrequired
          ? "Proceed to Payments"
          : $t("summary.placeOrder")
      }}</checkout-button
    >
    <back-arrow name="payment-method"> {{ $t("summary.back") }} </back-arrow>
  </div>
</template>

<script>
import SummaryItem from "@/components/checkout/SummaryItem";
import CheckoutButton from "@/components/common/CheckoutButton";
import BackArrow from "@/components/checkout/BackArrow.vue";

export default {
  name: "Summary",
  components: {
    BackArrow,
    SummaryItem,
    CheckoutButton,
  },
  props: {
    cart: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    isLoading: { type: Boolean, default: false },
    backorderItems: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const findField = (field) =>
      props.options[field].find((item) => item._id === props.cart[field]);

    const shippingAddress = findField("shippingaddress");
    const shippingMethod = findField("shippingmethod");
    const paymentMethod = findField("paymentmethod");

    const sendAsComplete = !!(
      props.cart.backorderquantity && props.cart.sendtogether
    );

    const placeOrder = () => ctx.emit("placeOrder");

    return {
      shippingAddress,
      sendAsComplete,
      shippingMethod,
      paymentMethod,
      placeOrder,
    };
  },
};
</script>

<style scoped lang="scss">
.header {
  font-size: 24px;
  font-weight: 600;
}
</style>
