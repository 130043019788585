<template>
  <div class="banner-wrapper">
    <div class="banner-background">
      <the-slider />
      <div class="img-right">
        <picture>
          <source type="image/webp" srcset="./../assets/o10_e2.webp" />
          <img src="./../assets/o10_e2.png" />
        </picture>
      </div>
      <div class="img-left">
        <picture>
          <source type="image/webp" srcset="./../assets/o10_e1.webp" />
          <img src="./../assets/o10_e1.png" />
        </picture>
      </div>
      <div class="border-triangle"></div>
    </div>
  </div>
</template>

<script>
import TheSlider from "./banner/TheSlider";

export default {
  name: "BannerComponent",
  components: { TheSlider },
};
</script>

<style lang="scss" scoped>
.banner-wrapper {
  font-family: "Michroma", sans-serif;

  .banner-background {
    background-color: white;
    position: relative;

    .border-triangle {
      background: white;
      width: 100%;
      height: 4em;
      bottom: -1px;
      z-index: 1;
      position: absolute;
      clip-path: polygon(
        50% 100%,
        55% 0,
        100% 0,
        100% 100%,
        0 100%,
        0 0,
        45% 0
      );

      @media screen and (max-width: 960px) {
        display: none;
      }
    }

    .img-right {
      position: absolute;
      bottom: 62px;
      pointer-events: none;
      right: 0;

      picture {
        display: flex;
        justify-content: flex-end;
      }

      img {
        width: 50%;
      }

      @media screen and (max-width: 1250px) {
        display: none;
      }
    }

    .img-left {
      position: absolute;
      left: -3%;
      top: 322px;
      z-index: 2;

      img {
        width: 150px;
      }

      @media screen and (max-width: 1250px) {
        display: none;
      }
    }

    .banner-text {
      line-height: 1.8em;
      background: rgba($color: #ffffff, $alpha: 0.9);

      .dsc {
        font-weight: bold;
        font-size: 14px;
        word-spacing: 3px;
        line-height: 1.5;
        white-space: normal;
        font-family: "Nunito Sans", sans-serif;
      }

      @media screen and (max-width: 1250px) {
        padding: 2em;
        text-align: center;
      }

      h2 {
        font-size: 22px;
        padding-bottom: 20px;
        color: #333;
        font-weight: bold;
        word-spacing: 10px;
        letter-spacing: 1.5px;
        line-height: 1.5;

        @media screen and (max-width: 1250px) {
          font-size: 18px;
        }
      }

      .link {
        color: var(--red);
        margin-left: 0.5em;
      }
    }

    .extra-container {
      padding-right: 25%;
      padding-left: 40px;
      padding-top: 12px;
      padding-bottom: 12px;

      @media screen and (max-width: 1250px) {
        padding: 0 10px;
      }
    }
  }
}
</style>
