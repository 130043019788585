<template>
  <div class="d-flex align-center flex-column wrapper">
    <div
      class="shipping-method d-flex justify-center align-center"
      :class="{ selected }"
      @click="selectPaymentMethod"
    >
      <img
        :src="getPaymentMethodPicture(paymentMethod.name)"
        :alt="paymentMethod.name"
      />
    </div>
    <div class="text-center description">
      <span class="mr-1">{{
        paymentMethod.description ||
        paymentMethod.displayname ||
        paymentMethod.name
      }}</span>
    </div>
  </div>
</template>

<script>
import getPaymentMethodPicture from "@/vars/paymentMethods";

export default {
  name: "PaymentMethod",
  props: {
    paymentMethod: { type: Object, required: true },
    selected: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const selectPaymentMethod = () =>
      ctx.emit("selectPaymentMethod", props.paymentMethod._id);

    return {
      getPaymentMethodPicture,
      selectPaymentMethod,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100px;
}

.shipping-method {
  border: 1px solid #707070;
  background-color: #f4f4f4;
  filter: grayscale(100%);
  border-radius: 50%;
  width: 75px;
  height: 75px;

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }

  &:hover {
    cursor: pointer;
    background-color: white;
    filter: grayscale(0);
  }
}

.description {
  padding-top: 8px;
  font-size: 12px;
}

.selected {
  border: 1px solid black;
  filter: grayscale(0);
}
</style>
