<template>
  <div class="brands-wrapper">
    <page-header :text="$t('ourBrands')" font="black" />
    <b-container>
      <div class="brand-items">
        <brand-circle
          v-for="brand in brandsFiltered"
          :key="brand.header"
          :brand="brand"
          class="brand-item"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BrandCircle from "@/components/brands/BrandCircle.vue";
import PageHeader from "@/components/common/PageHeader.vue";

const dba = require("./../../assets/kolecka/Kolecka_DBA.png");
const xp = require("./../../assets/kolecka/XP_1.png");
const xo = require("./../../assets/kolecka/XO_2.png");
const hawk = require("./../../assets/kolecka/o-hawk.jpg");
const xforce = require("./../../assets/kolecka/xforce1_lighter2.png");
const pedders = require("./../../assets/kolecka/o-pedders.jpg");
const turbosmart = require("./../../assets/kolecka/o-turbosmart.jpg");
const whiteline = require("./../../assets/kolecka/o-whiteline.jpg");
const redarc = require("./../../assets/kolecka/redarc1_180x180.png");
const acl = require("./../../assets/kolecka/o-acl.png");
const saber = require("./../../assets/kolecka/saber1_180x180.png");
const aeroflow = require("./../../assets/kolecka/Aeroflow_Ozparts_page.png");

const dbaWebp = require("./../../assets/kolecka/Kolecka_DBA.webp");
const xpWebp = require("./../../assets/kolecka/XP_1.webp");
const xoWebp = require("./../../assets/kolecka/XO_2.webp");
const hawkWebp = require("./../../assets/kolecka/o-hawk.webp");
const xforceWebp = require("./../../assets/kolecka/xforce1_lighter2.webp");
const peddersWebp = require("./../../assets/kolecka/o-pedders.webp");
const turbosmartWebp = require("./../../assets/kolecka/o-turbosmart.webp");
const whitelineWebp = require("./../../assets/kolecka/o-whiteline.webp");
const redarcWebp = require("./../../assets/kolecka/redarc1_180x180.webp");
const aclWebp = require("./../../assets/kolecka/o-acl.webp");
const saberWebp = require("./../../assets/kolecka/saber1_180x180.webp");
const aeroflowWebp = require("./../../assets/kolecka/Aeroflow_Ozparts_page.webp");

export default {
  name: "BrandsComponent",
  components: { BrandCircle, PageHeader },
  data: () => {
    return {
      brands: [
        {
          header: "DBA",
          dsc: "Disc Brakes & Pads",
          img: dba,
          imgWebp: dbaWebp,
          to: "/brands/dba",
        },
        {
          header: "XTREME CLUTCH",
          dsc: "High Performance Clutch & Components",
          img: xp,
          imgWebp: xpWebp,
          to: "/brands/xclutch",
        },
        {
          header: "XTREME OUTBACK",
          dsc: "4ED Clutch & Components",
          img: xo,
          imgWebp: xoWebp,
          to: "/brands/xoutback",
        },
        {
          header: "HAWK",
          dsc: "Brake Pads",
          img: hawk,
          imgWebp: hawkWebp,
          to: "/brands/hawk",
        },
        {
          header: "XFORCE",
          dsc: "Performance Exhaust",
          img: xforce,
          imgWebp: xforceWebp,
          to: "/brands/xforce",
        },
        {
          header: "PEDDERS",
          dsc: "Suspension, Shock Absorbers and Springs",
          img: pedders,
          imgWebp: peddersWebp,
          to: "/brands/pedders",
        },
        {
          header: "TURBOSMART",
          dsc: "Turbo Performance Products",
          img: turbosmart,
          imgWebp: turbosmartWebp,
          to: "/brands/turbosmart",
          hideOn: ["ozpartsuk"],
        },
        {
          header: "WHITELINE",
          dsc: "Stability Control Components",
          img: whiteline,
          imgWebp: whitelineWebp,
          to: "/brands/whiteline",
          hideOn: ["ozpartsuk"],
        },
        {
          header: "REDARC",
          dsc: "Voltage Converters & Electronics",
          img: redarc,
          imgWebp: redarcWebp,
          to: "/brands/redarc",
          hideOn: ["ozpartsuk"],
        },
        {
          header: "ACL RACE SERIES",
          dsc: "Performance Engine Bearings",
          img: acl,
          imgWebp: aclWebp,
          to: "/brands/acl",
          hideOn: ["ozpartsuk"],
        },
        {
          header: "SABER",
          dsc: "4WD Recovery Gear",
          img: saber,
          imgWebp: saberWebp,
          to: "/brands/saber",
        },
        {
          header: "AEROFLOW",
          dsc: "Performance Products",
          img: aeroflow,
          imgWebp: aeroflowWebp,
          to: "/brands/aeroflow",
        },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
    brandsFiltered() {
      return this.brands.filter(
        (brand) =>
          !(brand.hideOn && brand.hideOn.includes(this.$source)) ||
          (brand.header === "PEDDERS" && this.user && this.user.forpricegroups)
      );
    },
  },
};
</script>

<style lang="scss">
.brands-wrapper {
  h2 {
    text-align: center;
    text-transform: uppercase;
    word-spacing: 5px;
  }

  .line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid black;
    margin: 1em auto;
    padding: 0;
    margin-top: 1em;
    width: 10em;
  }

  .brand-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .brand-item {
      width: 200px;
      margin-top: 2em;
      display: flex;
      justify-content: center;

      @media screen and (min-width: 600px) {
        margin: 1em;
      }
    }
  }
}
</style>
