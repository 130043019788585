<template>
  <div>
    <h2 class="mb-6">{{ $t("paymentMethods.header") }}</h2>
    <payment-method-list
      :payment-methods="options.paymentmethod"
      :cart="cart"
      @paymentMethodUpdated="handlePaymentMethodUpdate"
    />
    <h2 class="my-6">{{ $t("paymentMethods.referenceNumberHeader") }}</h2>
    <v-text-field
      :value="cart.referencenumber"
      :label="$t('paymentMethods.referenceNumber')"
      outlined
      color="black"
      @input="debouncedHandleReferenceNumber"
    ></v-text-field>
    <checkout-button
      class="mt-4"
      :loading="isLoading"
      :disabled="
        !cart.paymentmethod ||
        isLoading ||
        !options.paymentmethod.some(
          (method) => method._id === cart.paymentmethod
        )
      "
      @click="goTo"
      >{{ $t("paymentMethods.continueToSummary") }}</checkout-button
    >
    <back-arrow name="shipping-method">
      {{ $t("paymentMethods.back") }}
    </back-arrow>
  </div>
</template>

<script>
import { BASKET_FIELD } from "../../vars/vars";
import { debounce } from "lodash";
import PaymentMethodList from "@/components/checkout/PaymentMethodList";
import BackArrow from "@/components/checkout/BackArrow.vue";
import CheckoutButton from "@/components/common/CheckoutButton";

export default {
  name: "PaymentMethod",
  components: {
    PaymentMethodList,
    BackArrow,
    CheckoutButton,
  },
  props: {
    cart: { type: Object, required: true },
    options: { type: Object, required: true },
    isLoading: { type: Boolean, default: false },
  },
  setup(_, ctx) {
    const handlePaymentMethodUpdate = (value) => {
      ctx.emit("updateFieldWithValue", {
        field: BASKET_FIELD.PAYMENT_METHOD,
        value,
      });
    };

    const handleReferenceNumber = (value) => {
      ctx.emit("updateFieldWithValue", {
        field: BASKET_FIELD.REFERENCE_NUMBER,
        value,
      });
    };

    const debouncedHandleReferenceNumber = debounce(handleReferenceNumber, 500);

    const goTo = () =>
      ctx.root.$router.push({
        name: "summary",
        params: { basket: ctx.root.$route.params.basket },
      });

    return {
      handlePaymentMethodUpdate,
      debouncedHandleReferenceNumber,
      goTo,
    };
  },
};
</script>

<style lang="scss" scoped></style>
