<template>
  <b-container class="override">
    <div class="slide-wrapper">
      <div class="rotating-banner">
        <div class="image">
          <router-link to="/about"><img :src="photo" /></router-link>
        </div>
        <div class="text">
          <div class="main">
            PERFORMANCE AND 4X4 PARTS <br />DISTRIBUTION WAREHOUSES IN EU &amp;
            UK
          </div>
          <div class="supplementary">
            Ozparts focus is the distribution of high-quality Australian
            automotive parts to the widely established network of more than 800
            trade customers via our warehouses in the EU and the UK. Among our
            valued partners are ACL, ACS, DBA, Pedders, Redarc, Turbosmart,
            Whiteline, Xforce and as of 2021 also Saber and
            Aeroflow.<router-link class="link" to="/about"
              >[read more]</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import photo from "@/assets/banner/home_au.png";

export default {
  name: "SaberSlide",
  data() {
    return {
      photo,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../../scss/variables.scss";

.override {
  padding: 0 20px;
  font-family: $body-font-family !important;
  font-weight: $body-font-weight !important;
}

.rotating-banner {
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 1000px) {
    display: grid;
    column-gap: 30px;
    grid-template-columns: 610px auto;
  }

  filter: grayscale(100%);

  &:hover {
    filter: grayscale(0);
  }

  .image {
    img {
      width: 100%;
      height: 100%;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .text {
    padding-top: 20px;
    font-weight: normal;
    width: 85%;

    .main {
      font-size: 19px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .supplementary {
      font-size: 13px;
      width: 100%;
      padding-bottom: 10px;

      .link {
        color: var(--red);
        margin-left: 5px;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
