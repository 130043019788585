<template>
  <marquee
    v-if="(marquee.show || this.message) && lang === 'en' && !isHidden"
    class="marquee"
    :class="dark && 'marquee-dark'"
    >{{ message || marquee.text_en }}</marquee
  >
  <marquee
    v-else-if="(marquee.show || this.message) && lang === 'pl' && !isHidden"
    class="marquee"
    :class="dark && 'marquee-dark'"
    >{{ message || marquee.text_pl }}</marquee
  >
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "TheMarquee",
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    marquee: {
      show: false,
      text_pl: "",
      text_en: "",
    },
  }),
  computed: {
    ...mapState(["lang"]),
    isHidden() {
      return this.$route && this.$route.meta && this.$route.meta.requiresAuth;
    },
  },
  async created() {
    this.setMarqueeInfo();
  },
  methods: {
    async setMarqueeInfo() {
      const urlBase =
        process.env.NODE_ENV === "production"
          ? "https://api.ozparts.eu"
          : "http://localhost:8000";
      const website =
        this.$source === "ozpartseu" ? "ozparts-eu" : "ozparts-uk";
      const url = `${urlBase}/marquees/${website}`;

      try {
        const res = await axios.get(url);
        if (res.data.show) {
          this.marquee = res.data;
        }
      } catch {}
    },
  },
};
</script>

<style lang="scss" scoped>
.marquee {
  background-color: var(--red);
  color: white;
  font-family: "Nunito Sans", sans-serif;
  padding: 2px 0;
  font-weight: bold;

  &-dark {
    background-color: black;
  }
}
</style>
