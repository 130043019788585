<template>
  <div class="background">
    <div class="banner-text">
      <b-container class="extra-container">
        <h2>{{ $t("banner") }}</h2>
        <p class="dsc">
          {{ $source === "ozpartseu" ? $t("banner1") : $t("banner1uk") }}
          <router-link to="/about" class="link"
            >[{{ $t("readMore") }}]</router-link
          >
        </p>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slide3",
};
</script>

<style lang="scss" scoped>
.background {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  align-items: center;
}

.extra-container {
  padding-right: 25%;
  padding-left: 40px;
  padding-top: 12px;
  padding-bottom: 12px;

  @media screen and (max-width: 1250px) {
    padding: 0 10px;
  }
}

.banner-text {
  line-height: 1.8em;
  background: rgba($color: #ffffff, $alpha: 0.9);
  width: 100%;
  margin-bottom: 100px;

  .dsc {
    font-weight: bold;
    font-size: 14px;
    word-spacing: 3px;
    line-height: 1.5;
    white-space: normal;
    font-family: "Nunito Sans", sans-serif;
  }

  @media screen and (max-width: 1250px) {
    padding: 2em;
    text-align: center;
  }

  h2 {
    font-size: 22px;
    padding-bottom: 20px;
    color: #333;
    font-weight: bold;
    word-spacing: 10px;
    letter-spacing: 1.5px;
    line-height: 1.5;

    @media screen and (max-width: 1250px) {
      font-size: 18px;
    }
  }

  .link {
    color: var(--red);
    margin-left: 0.5em;
  }
}
</style>

<style>
.no-webp .background {
  background-image: url("./../../assets/warehouse_Clean.jpg");
}

.webp .background {
  background-image: url("./../../assets/warehouse_Clean.webp");
}
</style>
