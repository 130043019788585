<template>
  <v-dialog :value="value.showAddressForm" max-width="640" persistent>
    <v-card>
      <v-card-title class="mb-4">Shipping Address</v-card-title>
      <v-card-text>
        <v-form ref="newAddressForm" v-model="valid">
          <v-text-field
            :value="value.addressee"
            color="black"
            outlined
            :rules="rules.required"
            label="Label"
            required
            @input="(e) => handleChange(e, 'addressee')"
          ></v-text-field>
          <v-text-field
            :value="value.address"
            color="black"
            outlined
            :rules="rules.required"
            label="Address"
            required
            @input="(e) => handleChange(e, 'address')"
          ></v-text-field>
          <v-text-field
            :value="value.address2"
            color="black"
            outlined
            label="Address (continued)"
            required
            @input="(e) => handleChange(e, 'address2')"
          ></v-text-field>
          <v-text-field
            :value="value.zip"
            color="black"
            outlined
            :rules="rules.required"
            label="Zip"
            required
            @input="(e) => handleChange(e, 'zip')"
          ></v-text-field>
          <v-text-field
            :value="value.city"
            color="black"
            outlined
            :rules="rules.required"
            label="City"
            required
            @input="(e) => handleChange(e, 'city')"
          ></v-text-field>
          <v-select
            :value="value.country"
            color="black"
            placeholder="Country"
            required
            outlined
            :rules="rules.required"
            :items="countriesArray"
            item-text="value"
            item-value="key"
            @change="(e) => handleChange(e, 'country')"
          />
          <v-text-field
            :value="value.phone"
            color="black"
            outlined
            label="Phone"
            @input="(e) => handleChange(e, 'phone')"
          ></v-text-field>
          <v-switch
            v-if="!value.default"
            v-model="showEmailField"
            class="mt-0"
            :label="$t('checkout.showEmailField')"
          ></v-switch>
          <v-text-field
            v-if="showEmailField"
            hint="This address will be used to inform about order dispatch etc."
            persistent-hint
            :value="value.email"
            color="black"
            outlined
            :rules="[...rules.email, ...rules.required]"
            label="Email Address"
            required
            @input="(e) => handleChange(e, 'email')"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleCancel">{{ $t("cancel") }}</v-btn>
        <v-btn text @click="handleOk">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import countries from "@/countries";
import { ref } from "@vue/composition-api";

export default {
  name: "CheckoutAddressForm",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  setup(props, ctx) {
    const rules = {
      required: [(v) => !!v || "This field is required"],
      email: [(v) => !v || /.+@.+\..+/.test(v) || "E-mail must be valid"],
    };
    const countriesArray = Object.keys(countries).map((key) => ({
      key,
      value: countries[key],
    }));

    const newAddressForm = ref();
    const showEmailField = ref(false);

    const handleChange = (e, field) =>
      ctx.emit("input", { ...props.value, [field]: e });

    const handleOk = () => {
      if (newAddressForm.value.validate()) {
        newAddressForm.value.resetValidation();
        ctx.emit("ok");
      }
    };
    const handleCancel = () => {
      newAddressForm.value.resetValidation();
      ctx.emit("cancel");
    };
    const valid = ref(true);

    return {
      handleOk,
      handleCancel,
      handleChange,
      newAddressForm,
      showEmailField,
      valid,
      rules,
      countriesArray,
    };
  },
};
</script>

<style lang="scss" scoped></style>
