import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.ozparts.eu",
});

export const getSlides = (source) =>
  instance.get(
    "/slider/" + (source === "ozpartseu" ? "ozparts-eu" : "ozparts-uk")
  );
