<template>
  <div class="upper-part">
    <div class="content d-flex">
      <div class="logo">
        <router-link to="/">
          <picture>
            <source
              type="image/webp"
              srcset="./../../../assets/ozparts_logo_standard.webp"
            />
            <img :src="logo" alt="Ozparts" width="200px" height="71px" />
          </picture>
        </router-link>
      </div>
      <v-spacer />
      <search-bar />
      <nav-buttons />
    </div>
  </div>
</template>

<script>
import NavButtons from "./NavButtons.vue";
import SearchBar from "./../SearchBar.vue";

const logo = require("./../../../assets/ozparts_logo_standard.jpg");

export default {
  name: "NavUpperPart",
  components: { NavButtons, SearchBar },
  data: () => ({
    logo,
  }),
};
</script>

<style lang="scss" scoped>
.logo {
  img {
    width: 200px;
  }
}

.search {
  width: 11em;
  margin-left: 2em;
  margin-top: -1em;
}
</style>

<style lang="scss">
.upper-part {
  .search {
    .v-label {
      font-size: 0.7em;
    }
  }
}

.search-deep {
  .v-input {
    margin-top: -20px;
    height: 10px;
  }

  .v-label {
    position: relative;
    top: 5px !important;
  }
}
</style>
