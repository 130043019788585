<template>
  <div class="meta-footer-wrapper">
    <v-footer height="auto" dark color="#2f2f2f">
      <b-container>
        <div v-if="lang === 'en'" class="footer-wrapper">
          <div class="sub-element1">
            <ul>
              <li v-for="link in links1c" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
            </ul>
          </div>
          <div class="sub-element2">
            <ul>
              <li v-for="link in links2" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
            </ul>
          </div>
          <div class="sub-element3">
            <ul>
              <li v-for="link in links3" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
            </ul>
          </div>
          <div class="sub-element4">
            <ul>
              <li v-for="link in links4" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
              <li class="link-item">
                <a target="_blank" href="https://www.facebook.com/Ozparts.eu/"
                  >Facebook</a
                >
              </li>
              <li class="link-item">
                <a target="_blank" href="https://www.instagram.com/ozparts/"
                  >Instagram</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div v-if="lang === 'pl'" class="footer-wrapper">
          <div class="sub-element1">
            <ul>
              <li v-for="link in links1plc" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
            </ul>
          </div>
          <div class="sub-element2">
            <ul>
              <li v-for="link in links2pl" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
            </ul>
          </div>
          <div class="sub-element3">
            <ul>
              <li v-for="link in links3pl" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
            </ul>
          </div>
          <div class="sub-element4">
            <ul>
              <li v-for="link in links4pl" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </b-container>
    </v-footer>
    <div class="footer-logo">
      <router-link to="/">
        <picture>
          <source
            type="image/webp"
            srcset="./../assets/ozparts_logo_standard.webp"
          />
          <img src="./../assets/ozparts_logo_standard.jpg" alt="Ozparts" />
        </picture>
      </router-link>
      <div class="copy">Ozparts © 2022 All Rights Reserved</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState(["lang"]),
    ...mapGetters(["isUserCustomer"]),
    links1c() {
      return this.isUserCustomer
        ? this.links1
        : this.links1.filter((link) => link.to !== "/panel");
    },
    links1plc() {
      return this.isUserCustomer
        ? this.links1pl
        : this.links1pl.filter((link) => link.to !== "/panel");
    },
  },
  name: "FooterComponent",
  data: () => {
    return {
      links1: [
        {
          name: "About Us",
          to: "/about",
        },
        {
          name: "Catalogues",
          to: "/catalogue",
        },
        {
          name: "Customer Centre",
          to: "/panel",
        },
        {
          name: "News",
          to: "/news",
        },
      ],
      links1pl: [
        {
          name: "O nas",
          to: "/about",
        },
        {
          name: "Katalog",
          to: "/catalogue",
        },
        {
          name: "Panel Użytkownika",
          to: "/panel",
        },
        {
          name: "Aktualności",
          to: "/news",
        },
      ],
      links2: [
        {
          name: "Returns",
          to: "/faq#return",
        },
        {
          name: "Payment Methods",
          to: "/faq#payment",
        },
        {
          name: "Warranties",
          to: "/faq#warranties",
        },
        {
          name: "Shipping Options",
          to: "/faq#shipping",
        },
      ],
      links2pl: [
        {
          name: "Zwroty",
          to: "/faq#return",
        },
        {
          name: "Metody płatności",
          to: "/faq#payment",
        },
        {
          name: "Gwarancje",
          to: "/faq#warranties",
        },
        {
          name: "Metody dostawy",
          to: "/faq#shipping",
        },
      ],
      links3: [
        {
          name: "Privacy Policy",
          to: "/privacy_policy_5",
        },
        {
          name: "Terms & Conditions",
          to: "/terms",
        },
        {
          name: "Cookies Policy",
          to: "/cookies-policy",
        },
      ],
      links3pl: [
        {
          name: "Polityka prywatności",
          to: "/privacy_policy_5",
        },
        {
          name: "Warunki i zobowiązania",
          to: "/terms",
        },
        {
          name: "Polityka Cookies",
          to: "/cookies-policy",
        },
      ],
      links4: [
        {
          name: "Help / FAQ",
          to: "/faq",
        },
        {
          name: "Contact",
          to: "/contact",
        },
      ],
      links4pl: [
        {
          name: "Pomoc / FAQ",
          to: "/faq",
        },
        {
          name: "Kontakt",
          to: "/contact",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.meta-footer-wrapper {
  .link-item {
    a {
      color: white;
    }
  }

  .footer-wrapper {
    box-sizing: border-box;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    letter-spacing: 0.1em;
    padding: 3em 0;
    line-height: 2em;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 1em;
    align-items: flex-start;
    grid-template-areas:
      "el1 el1 el2 el2"
      "el3 el3 el4 el4";

    .sub-element1 {
      grid-area: el1;
    }

    .sub-element2 {
      grid-area: el2;
    }

    .sub-element3 {
      grid-area: el3;
    }

    .sub-element4 {
      grid-area: el4;
    }

    ul {
      list-style: none;
      padding: 0;
    }

    @media screen and (min-width: 599px) {
      grid-template-areas: "el1 el2 el3 el4";
    }
  }
  .footer-logo {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em;

    .copy {
      font-size: smaller;
      margin-top: 10px;
      padding-left: 20px;
    }

    img {
      width: 200px;
    }
  }
}
</style>
