<template>
  <div novalidate class="my-4">
    <v-select
      outlined
      color="black"
      :rules="[(v) => !!v || $t('validations.required')]"
      required
      :label="$t('shippingMethodsObj.custom.selectCarrier')"
      :value="$store.state.customShipping.carrier"
      :items="carriers"
      @change="(value) => updateCustomShipping('carrier', value)"
    ></v-select>
    <v-select
      :label="$t('shippingMethodsObj.custom.selectMethod')"
      :rules="[(v) => !!v || $t('validations.required')]"
      outlined
      color="black"
      required
      :value="$store.state.customShipping.speed"
      :items="speeds"
      @change="(value) => updateCustomShipping('speed', value)"
    ></v-select>
    <v-select
      outlined
      required
      :rules="[(v) => !!v || $t('validations.required')]"
      :label="$t('shippingMethodsObj.custom.details')"
      :value="$store.state.customShipping.label"
      :items="labels"
      color="black"
      @change="(value) => updateCustomShipping('label', value)"
    ></v-select>
    <v-text-field
      v-if="$store.state.customShipping.label === 'Customer Account Number'"
      :rules="[(v) => !!v || $t('validations.required')]"
      required
      :value="$store.state.customShipping.labelNumber"
      :label="$t('shippingMethodsObj.custom.label')"
      outlined
      color="black"
      @input="(value) => updateCustomShipping('labelNumber', value)"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "CustomShipping",
  props: {
    validated: Boolean,
  },
  setup(_, ctx) {
    const carriers = [
      {
        value: "",
        text: ctx.root.$t("shippingMethodsObj.custom.selectCarrier"),
        disabled: true,
      },
      { value: "UPS", text: "UPS" },
      { value: "TNT", text: "TNT" },
      { value: "DPD", text: "DPD" },
      { value: "DHL", text: "DHL" },
      { value: "Fedex", text: "Fedex" },
      { value: "Other", text: ctx.root.$t("shippingMethodsObj.other") },
    ];
    const speeds = [
      {
        text: ctx.root.$t("shippingMethodsObj.custom.selectMethod"),
        value: "",
        disabled: true,
      },
      { value: "Economy", text: ctx.root.$t("shippingMethodsObj.economy") },
      { value: "Express", text: ctx.root.$t("shippingMethodsObj.express") },
    ];
    const labels = [
      {
        value: "",
        text: ctx.root.$t("shippingMethodsObj.custom.details"),
        disabled: true,
      },
      {
        value: "Customer Account Number",
        text: ctx.root.$t("shippingMethodsObj.customerAccountNumber"),
      },
      {
        value: "Shipping Label",
        text: ctx.root.$t("shippingMethodsObj.shippingLabel"),
      },
    ];

    const updateCustomShipping = (field, value) => {
      ctx.root.$store.commit("updateCustomShipping", {
        [field]: value,
      });
      if (field === "label") {
        const isLabelRequired = value === "Shipping Label";
        ctx.emit("isLabelRequired", isLabelRequired);
      }
    };

    return {
      speeds,
      labels,
      carriers,
      updateCustomShipping,
    };
  },
};
</script>

<style lang="scss" scoped></style>
