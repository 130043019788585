import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faSpinner,
  faSearch,
  faShoppingBag,
  faTimes,
  faShoppingCart,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faBars,
  faSearch,
  faShoppingBag,
  faTimes,
  faSpinner,
  faShoppingCart,
  faTruck
);

export { FontAwesomeIcon };
