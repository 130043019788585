<template>
  <div class="wrapper mb-4">
    <v-autocomplete
      v-model="selectedAddress"
      color="black"
      outlined
      hide-details
      :items="shippingAddresses"
      :label="$t('shippingAddress.selectAddress')"
      item-text="addressee"
      item-value="_id"
      @change="handleClick"
    >
      <template #item="data">
        <div class="d-flex flex-column py-2">
          <div class="font-weight-bold">
            {{ data.item.addressee || data.item.name }}
          </div>
          <div>{{ data.item.address }}</div>
          <div>{{ data.item.zip }} {{ data.item.city }}</div>
          <div>{{ data.item.countryname || data.item.country }}</div>
        </div>
      </template>
    </v-autocomplete>
    <v-dialog v-model="showModal" persistent max-width="320">
      <v-card>
        <v-card-title class="text-h5">
          Would you like to change order currency?
        </v-card-title>

        <v-card-text>
          It seems that selected delivery address country could use
          {{ differentCurrency }} instead of {{ currentCurrency }}. Would you
          like to change the currency?
        </v-card-text>

        <v-card-actions>
          <v-btn
            depressed
            color="primary"
            @click="updateAddress(selectedId, true)"
          >
            Yes, change to {{ differentCurrency }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn text @click="updateAddress(selectedId)"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useAddressFilter } from "@/composables/useAddressFilter";
import { onMounted, ref, watch, computed, inject } from "@vue/composition-api";
import { BASKET_FIELD } from "../../vars/vars";

export default {
  name: "ShippingAddressList",
  props: {
    shippingAddresses: {
      type: Array,
      default: () => [],
    },
    cart: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const source = inject("source");
    const { query, resetQuery, filteredShippingAddresses } = useAddressFilter(
      props.shippingAddresses
    );

    const selectedAddress = ref(props.cart.shippingaddress);
    const showModal = ref(false);
    const selectedId = ref("");
    const currentCurrency = computed(
      () => props.cart && props.cart.currency && props.cart.currency.iso
    );
    const differentCurrency = computed(() =>
      currentCurrency.value === "PLN"
        ? source === "ozpartseu"
          ? "EUR"
          : "GBP"
        : "PLN"
    );

    watch(
      () => props.cart.shippingaddress,
      (newValue) => {
        selectedAddress.value = newValue;
      }
    );

    const setDefaultIfEmpty = () => {
      if (!props.cart.shippingaddress) {
        const candidate = props.shippingAddresses.find((addr) => addr.default);
        if (candidate) {
          ctx.emit("updateWithValue", candidate._id);
        }
      }
    };

    onMounted(setDefaultIfEmpty);

    const updateAddress = (value, updateCurrency = false) => {
      showModal.value = false;
      const changes = [];
      if (updateCurrency) {
        changes.push({
          field: BASKET_FIELD.CURRENCY,
          value: differentCurrency.value,
        });
      }
      changes.push({
        field: BASKET_FIELD.SHIPPING_ADDRESS,
        value: value,
      });
      ctx.emit("updateWithValue", changes);
    };

    const handleClick = (value) => {
      const { country } = props.shippingAddresses.find(
        (add) => add._id === value
      );
      const currencyUpdatePossible =
        (currentCurrency.value !== "PLN" && country === "PL") ||
        (currentCurrency.value === "PLN" && country !== "PL");
      if (currencyUpdatePossible) {
        selectedId.value = value;
        showModal.value = true;
      } else {
        updateAddress(value);
      }
    };

    return {
      showModal,
      handleClick,
      selectedAddress,
      query,
      filteredShippingAddresses,
      resetQuery,
      currentCurrency,
      differentCurrency,
      selectedId,
      updateAddress,
    };
  },
};
</script>

<style lang="scss" scoped>
.clear:hover {
  cursor: pointer;
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media screen and (min-width: 960px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
