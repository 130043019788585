<template>
  <div class="content pr-4">
    <backorder-item
      v-for="product in backorderItems"
      :key="product._id"
      :product="product"
    />
  </div>
</template>

<script>
import BackorderItem from "./BackorderItem.vue";

export default {
  name: "BackorderItemList",
  components: { BackorderItem },
  props: {
    backorderItems: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  height: 420px;
  overflow-y: scroll;

  @media screen and (min-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
