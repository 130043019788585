<template>
  <v-btn
    :disabled="disabled"
    :loading="loading"
    block
    :class="gray ? 'my-gray' : 'black'"
    depressed
    class="btn-checkout"
    height="56px"
    @click="handleClick"
  >
    <slot></slot
  ></v-btn>
</template>

<script>
export default {
  name: "CheckoutButton",
  props: {
    gray: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, ctx) {
    const handleClick = () => ctx.emit("click");

    return {
      handleClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-checkout {
  font-family: "Nunito Sans";
  text-transform: capitalize !important;
  font-weight: 600;
}

.black {
  background-color: black;
  color: white;
}

.my-gray {
  color: #000000de;
  background-color: #f3f3f3;
}
</style>
