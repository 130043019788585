<template>
  <div class="wrapper d-flex justify-space-around flex-wrap">
    <payment-method
      v-for="method in paymentMethods"
      :key="method._id"
      :payment-method="method"
      :selected="cart.paymentmethod === method._id"
      @selectPaymentMethod="handleClick"
    />
  </div>
</template>

<script>
import PaymentMethod from "@/components/checkout/PaymentMethod";
import { onMounted } from "@vue/composition-api";

export default {
  name: "PaymentMethodList",
  components: { PaymentMethod },
  props: {
    paymentMethods: {
      type: Array,
      default: () => [],
    },
    cart: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const setDefaultIfEmpty = () => {
      if (!props.cart.paymentmethod) {
        const candidate = props.paymentmethod.find((method) => method.default);
        if (candidate) {
          ctx.emit("paymentMethodUpdated", candidate._id);
        }
      }
    };

    onMounted(setDefaultIfEmpty);

    const handleClick = (value) => {
      ctx.emit("paymentMethodUpdated", value);
    };

    return { handleClick };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media screen and (min-width: 960px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
