<template>
  <div class="buttons">
    <v-btn v-if="!isUserLogged" text small class="nav-button-main" to="/login">
      <v-icon class="nav-icon">mdi-account</v-icon>
      {{ $t("login") }}
    </v-btn>
    <v-btn
      v-else-if="isUserCustomer"
      text
      small
      class="nav-button-main"
      to="/panel"
    >
      <v-icon class="nav-icon">mdi-account</v-icon>
      {{ $t("myAccount") }}
    </v-btn>
    <v-btn
      v-if="isUserLogged"
      text
      small
      class="nav-button-main"
      @click="logout"
    >
      <v-icon class="nav-icon">mdi-account</v-icon>
      {{ $t("logout") }}
    </v-btn>
    <v-btn
      v-if="isUserCustomer"
      text
      small
      class="nav-button-main"
      to="/basket"
    >
      <v-icon class="nav-icon">mdi-cart</v-icon>
      <v-badge right bottom color="primary">
        <span slot="badge">{{ getBasketCount }}</span>
        <span>{{ $t("basket") }}</span>
      </v-badge>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavButtons",
  methods: {
    logout() {
      this.$store.commit("logOut");
      if (this.$route.name !== "home") {
        this.$router.push("/");
      }
    },
  },
  computed: {
    ...mapGetters([
      "getUser",
      "isUserLogged",
      "getBasketCount",
      "isUserCustomer",
    ]),
  },
};
</script>

<style scoped lang="scss">
.buttons {
  display: flex;
  align-items: center;

  .nav-icon {
    margin-right: 0.5em;
    font-size: 1.5em;
  }

  .nav-button-main {
    text-transform: unset;
    letter-spacing: 0.5px;
    font-family: "Nunito Sans", sans-serif;
    margin-right: 1em;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
