<template>
  <div>
    <div class="additional">
      <div class="logo d-flex justify-center">
        <picture>
          <source :srcset="payuLogoWebp" type="image/webp" />
          <img :src="payuLogo" alt="PayU" @click="handlePayu" />
        </picture>
      </div>
    </div>
    <modal-loader v-model="modalLoader" />
  </div>
</template>

<script>
import axios from "axios";
import ModalLoader from "@/components/common/ModalLoader.vue";
import payuLogo from "@/assets/payu.png";
import payuLogoWebp from "@/assets/payu.webp";
import { mapState } from "vuex";

export default {
  name: "PayU",
  components: { ModalLoader },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      token: "",
      modalLoader: false,
      payuLogo,
      payuLogoWebp,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async handlePayu() {
      const dev = [
        "dev.ozparts.uk",
        "dev.ozparts.eu",
        "localhost",
        "refresh2021.ozparts.eu",
      ];
      const { firstname, lastname, email } = this.user;
      const currency = this.order.currency.iso.toLowerCase();
      const url = `https://api.ozparts.eu/payments/payu/${currency}`;
      const user = {
        firstname,
        lastname,
        email,
      };
      const isDev =
        process.env.NODE_ENV !== "production" ||
        dev.includes(document.location.hostname);
      const source = this.$source === "ozpartseu" ? "ozparts.eu" : "ozparts.uk";

      try {
        const res = await axios.post(url, {
          order: this.order,
          user,
          source,
          isDev,
        });
        if (res.data.status.statusCode === "SUCCESS") {
          if (this.user.paymentrequired) {
            this.$emit("success");
          } else {
            window.open(res.data.redirectUri);
          }
        }
      } catch (error) {
        this.$emit("error", error);
      } finally {
        this.modalLoader = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.additional {
  .additional-header {
    background-color: black;
    color: white;
    padding: 5px 15px;
    font-family: "Michroma", sans-serif;
    font-size: 14px;
    margin: 20px 0;
  }
}

.logo {
  img {
    width: 150px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
