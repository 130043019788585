<template>
  <div class="home">
    <banner-component />
    <brands-component id="brands" />
    <news-component
      v-if="lang === 'en'"
      :observer="observer"
      :load-news="loadNews"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BannerComponent from "@/components/Banner";
import BrandsComponent from "@/components/brands/Brands";

export default {
  name: "Home",
  components: {
    BannerComponent,
    BrandsComponent,
    NewsComponent: () => import("@/components/news/News.vue"),
  },
  data() {
    return {
      observer: null,
      loadNews: false,
    };
  },
  computed: {
    ...mapState(["lang"]),
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      root: this.$el,
    });
    this.checkCountry();
  },
  methods: {
    ...mapActions(["checkCountry"]),
    onElementObserved(entries) {
      const { target, isIntersecting } = entries[0];
      if (!isIntersecting) {
        return;
      }

      this.observer.unobserve(target);
      this.loadNews = true;
    },
  },
};
</script>
