<template>
  <v-dialog v-model="showModal" persistent width="500">
    <v-card class="popup">
      <div class="oz-close pa-2" @click="closeModal">X</div>
      <div class="icon">
        <v-icon color="white" class="icon-item my-4"
          >mdi-exclamation-thick</v-icon
        >
        <p class="icon-text px-4">{{ $t("ON_HOLD_POPUP.HEADER") }}</p>
      </div>
      <div class="content pa-4">{{ $t("ON_HOLD_POPUP.TEXT") }}</div>
      <div class="popup-controls pa-6">
        <v-btn
          class="white--text"
          depressed
          color="#ed3730"
          @click="goToHistory"
          >{{ $t("ON_HOLD_POPUP.BUTTON") }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import { useState } from "vuex-composition-helpers";

export default {
  name: "OnHoldPopup",
  setup(_, ctx) {
    const { user } = useState(["user"]);

    const confirmed = ref(false);

    const closeModal = () => {
      confirmed.value = true;
    };

    const showModal = computed(
      () => !confirmed.value && user.value && user.value.accountonhold
    );

    const goToHistory = () => {
      ctx.root.$router.push("/history?type=Overdue").catch(() => {});
      closeModal();
    };

    return {
      showModal,
      closeModal,
      goToHistory,
    };
  },
};
</script>

<style lang="scss" scoped>
.popup {
  background-color: rgb(245, 245, 245);
  position: relative;

  .oz-close {
    background-color: lightgray;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .content {
    background-color: lightgray;
    font-weight: 500;
  }

  .popup-controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-text {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 24px;
  }

  .icon-item {
    background-color: var(--red);
    border-radius: 50%;
    font-size: 120px;
  }
}
</style>
